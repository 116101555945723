@import "../../scss/_variables.scss";

.header {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    font-size: 24px;
    line-height: 30px;
    font-weight: bold;
    color: $gray-1;
    flex-wrap: wrap;

    &.account {
    }

    &.justify-content-between {
        justify-content: space-between;
    }
}
