@import "../../scss/variables";

.btn {
    background-color: $yellow-1;
    border: 0;
    border-radius: 4px;
    box-shadow: $button-shadow;
    color: $gray-1;
    font-size: 18px;
    font-weight: 700;
    height: 40px;
    padding: 0px 5px;
    width: 100%;
    outline: none;

    &:focus {
        background-color: $yellow-2;
        outline: none;
    }

    &:hover {
        background-color: $yellow-2;
    }

    &:active {
        background-color: $yellow-3;
    }
    // font-size
    &.fs-medium {
        font-size: 14px;
    }

    &.fs-small {
        font-size: 12px;
    }
    // width
    &.w-large {
        width: 245px;
    }

    &.w-medium {
        width: 170px;
    }

    &.w-small {
        width: 135px;
    }
    // height
    &.short {
        height: 36px;
    }
    // style
    &.secondary {
        background-color: $gray-5;

        &:hover {
            background-color: $gray-4;
        }

        &:focus {
            background-color: $gray-4;
            outline: none;
        }

        &:active {
            background-color: $gray-3;
        }

        &.flat {
            background-color: white;
            color: $blue-1;

            &:hover {
                background-color: $gray-4;
            }

            &:focus {
                background-color: $gray-4;
                outline: none;
            }

            &:active {
                background-color: $gray-3;
            }

            &:disabled {
                background-color: transparent;
                color: $gray-3;
            }
        }
    }

    &.flat {
        box-shadow: none;

        &:hover {
            background-color: $yellow-2;
        }

        &:focus {
            background-color: $yellow-2;
            outline: none;
        }

        &:active {
            background-color: $yellow-3;
        }
    }

    &.outlined {
        border: 1px solid $gray-2;
        background-color: $white;

        &:hover {
            background-color: $gray-4;
        }

        &:focus {
            background-color: $gray-5;
            outline: none;
        }

        &:active {
            background-color: $gray-3;
        }
    }

    &:disabled {
        background-color: $gray-4;
        color: $gray-2;
        box-shadow: none;
    }
}

/*a button that looks like an anchor*/
.anchor-btn {
    padding: 0;
    border: none;
    background-color: transparent;
    color: $blue-1;

    &:hover {
        color: $blue-2;
    }
}

.close {
    background-color: transparent;
    border: none;
    background-position: center;
    background-repeat: no-repeat;
    height: 24px;
    width: 24px;
    background-image: url("data:image/svg+xml,<svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'><rect width='18.8561' height='3.77123' rx='0.5' transform='matrix(0.707106 -0.707108 0.707106 0.707108 0 13.334)' fill='%238A93A3'/><rect width='3.77123' height='18.8561' rx='0.5' transform='matrix(0.707106 -0.707108 0.707106 0.707108 0 2.66602)' fill='%238A93A3'/></svg>");

    &:hover {
        background-image: url("data:image/svg+xml,<svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'><rect width='18.8561' height='3.77123' rx='0.5' transform='matrix(0.707106 -0.707108 0.707106 0.707108 0 13.334)' fill='black'/><rect width='3.77123' height='18.8561' rx='0.5' transform='matrix(0.707106 -0.707108 0.707106 0.707108 0 2.66602)' fill='black'/></svg>");
        cursor: pointer;
    }
}

/*
    need disabled attributes
    auto pick type when not specified for when within popup??  probably not
    combination like active & disabled, make sure priority is correct
    height will change for different styles
*/
