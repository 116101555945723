@import "../../scss/_variables.scss";

.container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 10px;

    &.vertical {
        flex-flow: column;
        align-items: initial;
        margin-bottom: 3px;

        > div {
            margin: 0px;
        }

        .validation-container {
            width: 100%;
        }
    }

    &.method-styling {
        > div {
            height: 60px;
        }

        label {
            /*margin-left: -22px;*/
            height: 60px;
            width: 100%;
        }
    }
    /*> label {
		font-size: 18px;
		line-height: 24px;
		font-weight: bold;
		margin-bottom: 10px;
		width: 100%;
	}*/
    > div {
        margin-left: 10px;
        margin-right: 10px;
    }

    > div:first-child {
        margin-left: 0px;
    }

    > div:last-child {
        margin-right: 0px;
    }

    > .validation-container {
        flex: 0 0 100%;
    }
}
