@import "../../scss/_variables.scss";

.container {
    display: flex;
    width: 100%;

    > div:first-child {
        flex: 0 0 200px;
        display: none;
    }

    .categories {
        flex-direction: column;
        display: none;
        flex: 1 1 auto;

        > div:first-child {
            margin-top: 5px;
            margin-bottom: 15px;
            display: none;
        }

        > h2 {
            font-size: 20px;
            line-height: 26px;
            padding: 10px;
            background-color: $gray-5;
        }

        > h3 {
            font-size: 18px;
            line-height: 24px;
            padding: 10px;
            margin-left: 15px;
            margin-bottom: 0;
        }

        > h3 ~ div {
            display: flex;
            flex-wrap: wrap;
            margin-left: 25px;
            margin-bottom: 10px;

            > a {
                margin-bottom: 10px;
                margin-right: 10px;
            }
        }
    }

    .second-level {
        display: flex;
        flex-direction: column;

        &.hide {
            display: none;
        }
    }

    .mobile {
        width: 100%;
    }
}

@include media-lg {
    .container {
        > div:first-child {
            display: block;
            flex: 0 0 285px;
        }

        .categories {
            display: flex;

            > div:first-child {
                display: flex;
            }
        }
    }
}
