@import "../../scss/_variables.scss";

.carouselContainer {
    display: flex;
    flex-wrap: wrap;
    padding: 10px 0px;
    gap: 10px;
    justify-content: center;
    min-height: 200px;
}

.productCard {
    text-align: left;
    cursor: pointer;
    width: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #ddd;
    padding: 10px;
    background-color: #fff;
    transition: transform 0.2s;

    &:hover {
        transform: scale(1.05);
    }
}

.productImage {
    width: 100px;
    height: 100px;
    object-fit: contain;
    border-radius: 10px;
    margin: 0 auto 10px auto; /* Center the image */
    background-color: #fff;
    display: block;
}

.productTitle {
    font-size: 14px;
    font-weight: 500;
    color: #333;
    margin-bottom: 5px;
    text-align: center;
}

.productPrice {
    font-size: 17px;
    font-weight: bold;
    color: #000;
    margin-bottom: 5px;
    text-align: center;
}

.seeMore {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    border-radius: 8px;
    text-decoration: none;
    color: #007bff;
    font-weight: bold;
}
