@import "../../../scss/_variables.scss";

.header {
    display: none;
}

.container {
    width: 100%;

    > form {
        width: 100%;
    }
}

.paypal {
    display: flex;
    flex-direction: column;
    min-height: 124px;

    > div {
        margin-bottom: 10px;
    }
}

.tablet-buttons {
    display: none;
}

.sameAsShippingCheckbox {
    margin-bottom: 10px;
}

.sameAsShippingCheckbox label:after {
    content: "" !important;
}

@include media-md {
    .header {
        display: block;
    }
    .paypal-button {
        max-width: 245px;
    }
    .paypal {
        button {
            width: 245px;
        }
    }
    .tablet-buttons {
        display: block;
        margin-top: 10px;
    }
    .mobile-buttons {
        display: none;
    }
}
