@import "../../scss/_variables.scss";

@mixin SideBar {
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    padding-left: 25px;
    background-color: $white;
    position: fixed;
    width: 80vw;
    height: 100vh;
    min-width: 180px;
    z-index: 11;
    border-radius: 0 0 4px 0;
    transition: 0.2s ease-in;

    &.slide-Out {
        left: 0vw;
    }

    &.slide-In {
        left: -100vw;
    }

    .close-icon-container {
        position: absolute;
        right: -55px;
        top: 0;
        background-color: transparent;
        border: none;
        animation: fadeIn ease-in 0.5s;
        padding: 10px;
        .close-icon {
            width: 30px;
            height: 30px;
            background-repeat: no-repeat;
            background-image: url("data:image/svg+xml,%3Csvg width='35' height='35' viewBox='0 0 35 35' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M25.7734 9.22676L9.22709 25.7731' stroke='white' stroke-width='3' stroke-linejoin='round'/%3E%3Cpath d='M9.22656 9.22681L25.7729 25.7731' stroke='white' stroke-width='3' stroke-linejoin='round'/%3E%3C/svg%3E");
        }
    }

    .body {
        border: none;
        background-color: transparent;
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    @include media-md {
        width: 300px;
    }
}

@include media-not-md {
    .sideBar {
        @include SideBar;
    }
}

@include media-md {
    .sideBar:not(.mobileOnly) {
        @include SideBar;
    }
    .mobileOnly {
        .close-icon-container {
            display: none;
        }
    }
}
