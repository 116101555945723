@import "../../scss/_variables.scss";
.departments-links {
    //     left: 0;
    //     animation: slideLeft ease-in 0.3s;
    //     padding-left: 25px;
    //     //padding-right: 20px;

    > a,
    button {
        padding: 10px;
    }
}

.admin {
    cursor: pointer;
    color: white;
    font-size: 14px;
    font-weight: bold;
    background-color: transparent;
    border: none;
}

.departments-links {
    display: flex;
    flex-direction: column;

    .my-account-container-mobile {
        border: none;
        background-color: transparent;
        border-bottom: 1px solid black;
        padding: 10px 0px;
        margin-right: 10px;

        > div {
            display: flex;

            .person-icon {
                border: 2px solid black;
                padding: 11px;
                border-radius: 100%;
                margin-right: 10px;
                width: 24px;
                height: 24px;
                background-position: center;
                background-repeat: no-repeat;
                background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M15 5C15 7.75625 12.7625 10 10 10C7.2375 10 5 7.75625 5 5C5 2.2375 7.2375 0 10 0C12.7625 0 15 2.2375 15 5ZM0 17.5C0 14.1687 6.66875 12.5 10 12.5C13.3313 12.5 20 14.1687 20 17.5V20H0V17.5Z' fill='black'/%3E%3C/svg%3E");
            }

            .name-container {
                display: flex;
                align-self: center;
                justify-content: space-between;

                a {
                    font-size: small;
                }
            }
        }
    }

    > a,
    .admin {
        font-weight: 300;
        color: $gray-1;
        text-align: left;
        font-size: 16px;

        &:hover {
            background-color: $gray-4;
        }
    }
}

@include media-md {
    .departments-links {
        // width: 300px;

        .my-account-container-mobile {
            margin-right: 20px;
        }
    }
}
