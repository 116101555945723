@import "../../scss/_variables.scss";

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #465061;
    padding: 30px;
    width: 100%;

    .column {
        color: $gray-4;
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        width: 100px;

        > span {
            margin-bottom: 15px;
            font-size: 20px;
            font-weight: 700;
        }

        > a {
            color: $gray-4;
        }

        > a:hover {
            color: white;
        }
    }

    .media-boxes {
        display: flex;

        > a {
            margin-right: 20px;
        }

        span {
            display: none;
        }
    }
}

@include media-md {
    .container {
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;

        .column {
            margin-right: 50px;
        }

        .column:last-child {
            margin-right: 0;
        }

        .media-boxes {
            align-self: flex-end;
        }
    }
}

@include media-lg {
    .container {
        .column {
            margin-right: 100px;

            > span {
                font-size: 24px;
            }
        }
    }
}

@media print {
    .container {
        display: none;
    }
}
