@import "../../scss/variables";

.was-validated {
    input[type="checkbox"]:invalid ~ label::before {
    }
}

.container {
    position: relative;
    min-height: 1.5rem;
    padding-left: 1.5rem;

    &.inline {
        display: inline-flex;

        + .container {
            margin-left: 10px;
        }
    }

    &.clicked {
        label {
            &::before {
                border: 1px solid $gray-2;
                box-shadow: none;
            }
        }
    }

    .checkbox {
        padding: 0;
        position: absolute;
        opacity: 0;
        z-index: -1;
        width: min-content;

        &:checked {
            ~ label::before {
                background-color: $yellow-1;
                border: 1px solid $gray-1;
            }

            ~ label::after {
                background-image: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'><path fill='%2324272C' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/></svg>");
            }
        }

        &:focus + label::before {
            border: 1px solid $yellow-2;
            box-shadow: 0px 0px 6px $yellow-1;
        }

        &:disabled {
            ~ label {
                cursor: default;
                color: $gray-3;

                &::before {
                    border: 1px solid $gray-5;
                    background-color: $gray-4;
                }

                &:hover::before {
                    box-shadow: none;
                }
            }
        }
    }

    label {
        display: inline-flex;
        align-items: center;
        margin-bottom: 0;
        font-size: 14px;
        line-height: 20px;
        cursor: pointer;

        &::before {
            position: absolute;
            top: 0.15rem;
            left: 0;
            display: block;
            width: 20px;
            height: 20px;
            border: 3px solid $white;
            pointer-events: none;
            content: "";
            user-select: none;
            background-color: $white;
            border: 1px solid $gray-2;
            border-radius: 2px;
        }

        &::after {
            position: absolute;
            top: 0.15rem;
            left: 0;
            display: block;
            width: 20px;
            height: 20px;
            content: "";
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 50% 50%;
        }

        &:hover::before {
            border: 1px solid $gray-1;
            box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.5);
        }
    }
}
