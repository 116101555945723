@import "./scss/variables";

.all-contents {
    position: relative;
    min-height: 100vh;
    width: 100%;

    > div:last-child {
        //Footer
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 434.4px;
    }
}

.container {
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    padding-bottom: 434.4px;

    > div:not(:global(.loading)) {
        max-width: 1440px;
        width: 100%;
        padding: 10px;
    }
    /*> div:first-child {
		flex: 0 0 285px;
	}
	>div:last-child{
		flex: 1 1 auto;
	}*/
}

.overlay {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    position: absolute;
    z-index: 10;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.7);
}

@include media-md {
    .all-contents {
        > div:last-child {
            //Footer
            height: 187px;
        }
    }
    .container {
        padding-bottom: 187px;

        > div:not(:global(.loading)) {
            padding: 20px 20px 20px 20px;
        }
    }
}

@media print {
    .container {
        padding: 0px;

        > div:not(:global(.loading)) {
            padding: 0px;
        }
    }
}
