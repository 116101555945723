@import "../../scss/variables";

.container {
    .horizontal-label {
        display: flex;

        label {
            flex: 0 0 auto;
            margin: 6px 10px 0px 0px;
        }

        > div {
            display: flex;
            flex-direction: column;
            flex: 1;
        }
    }

    label {
        font-size: 14px;

        &.disabled {
            color: $gray-3;
        }
    }

    .select {
        font-size: 14px;
        outline: none;
        width: 100%;
        padding: 0.375rem 0.75rem;
        border: 1px solid $gray-3;
        border-radius: 4px;
        height: 36px;
        /*margin-bottom: 5px;*/
        box-sizing: border-box;
        appearance: none; //hides browser default dropdown arrow
        -moz-appearance: none;
        -webkit-appearance: none;
        background-position: right 10px top 50%;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml,<svg width='12' height='6' viewBox='0 0 12 6' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M12 7.62939e-06L6.00016 6.00001L0 7.62939e-06' fill='%231072E5'/></svg>");

        &:hover {
            border: 1px solid $gray-1;
        }

        &:active,
        &:focus {
            border: 1px solid $yellow-2;
            box-shadow: 0px 0px 6px $yellow-1;
            outline-color: $yellow-2;
        }

        &::-ms-expand {
            display: none; //hides ie11 default dropdown arrow
        }

        &:disabled {
            border: 1px solid $gray-4;
            background-color: $gray-5;
            background-image: url("data:image/svg+xml,<svg width='13' height='7' viewBox='0 0 13 7' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M12.1445 0L6.14469 6L0.144531 0' fill='%23C8CBD3'/></svg>");
        }
    }
}
