//all
.p-0 {
    padding: 0px;
}

.p-5 {
    padding: 5px;
}

.p-10 {
    padding: 10px;
}

.p-15 {
    padding: 15px;
}

.p-20 {
    padding: 20px;
}

.p-25 {
    padding: 25px;
}

.p-30 {
    padding: 30px;
}

//left
.pl-0 {
    padding-left: 0px;
}

.pl-5 {
    padding-left: 5px;
}

.pl-10 {
    padding-left: 10px;
}

.pl-15 {
    padding-left: 15px;
}

.pl-20 {
    padding-left: 20px;
}

.pl-25 {
    padding-left: 25px;
}

.pl-30 {
    padding-left: 30px;
}

//right
.pr-0 {
    padding-right: 0px;
}

.pr-5 {
    padding-right: 5px;
}

.pr-10 {
    padding-right: 10px;
}

.pr-15 {
    padding-right: 15px;
}

.pr-20 {
    padding-right: 20px;
}

.pr-25 {
    padding-right: 25px;
}

.pr-30 {
    padding-right: 30px;
}

//top
.pt-0 {
    padding-top: 0px;
}

.pt-5 {
    padding-top: 5px;
}

.pt-10 {
    padding-top: 10px;
}

.pt-15 {
    padding-top: 15px;
}

.pt-20 {
    padding-top: 20px;
}

.pt-25 {
    padding-top: 25px;
}

.pt-30 {
    padding-top: 30px;
}

//bottom
.pb-0 {
    padding-bottom: 0px;
}

.pb-5 {
    padding-bottom: 5px;
}

.pb-10 {
    padding-bottom: 10px;
}

.pb-15 {
    padding-bottom: 15px;
}

.pb-20 {
    padding-bottom: 20px;
}

.pb-25 {
    padding-bottom: 25px;
}

.pb-30 {
    padding-bottom: 30px;
}
