@import "../../scss/variables";

.title {
    font-size: 18px;
    line-height: 24px;
    font-weight: bold;
    color: $gray-1;
    margin-bottom: 10px;

    &.inline {
        width: auto;
        display: inline-block;
    }
}
