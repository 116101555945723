@import "../../../scss/_variables.scss";

.container {
    :global(.card-header) {
        display: flex;

        > span:nth-child(2),
        span:nth-child(3),
        span:nth-child(4) {
            display: none;
        }
    }

    .mobile-shipping,
    .right-side {
        font-size: 14px;

        /*        > div:first-of-type {
            font-weight: 700;
        }*/
    }

    .green {
        color: $green;
        font-weight: 700;
    }

    :global(.card-body) {
        padding: 10px 0px;

        > .mobile-shipping {
            border-bottom: 1px solid $gray-3;
            padding: 0px 10px 10px 10px;

            > p {
                margin-bottom: 0px;
                font-size: 14px;
            }
        }

        > .item-group {
            > .item {
                border-bottom: 1px solid $gray-3;
                padding: 10px;

                > .price,
                .quantity {
                    display: none;
                }

                &:last-child {
                    border-bottom: none;
                }
            }
        }

        > .right-side {
            display: none;
            border-left: 1px solid $gray-3;
            padding-left: 20px;
        }
    }
}

@include media-md {
    .container {
        :global(.card-header) {
            /*> span:last-child {
                padding-left: 20px;
                flex: 0 0 285px;
            }*/
        }

        :global(.card-body) {
            padding: 20px;
            display: flex;
            justify-content: space-around;

            > .mobile-shipping {
                display: none;
            }

            > .item-group {
                flex: 2;
                padding: 0px;
                margin-right: 20px;

                > .item {
                    padding: 20px 0px;

                    &:first-child {
                        padding-top: 0px;
                    }

                    &:last-child {
                        padding-bottom: 0px;
                    }
                }
            }

            > .right-side {
                display: flex;
                flex: 1;
                flex-direction: column;

                > p {
                    margin-bottom: 0px;
                    font-size: 14px;
                }

                > div {
                    padding: 0px 10px 10px 0px;
                }
            }
        }
    }
}

@include media-xl {
    .container {
        :global(.card-header) {
            > span:first-child {
                flex: 3;
            }

            > span:nth-child(2),
            span:nth-child(3) {
                display: block;
                flex: 1;
            }

            > span:nth-child(3) {
                margin-right: 20px;
            }

            > span:nth-child(4) {
                display: block;
                flex: 0 0 300px;
                padding-left: 20px;
            }
        }

        :global(.card-body) {
            > .item-group {
                > .item {
                    display: flex;

                    > div:first-child {
                        flex: 3;
                    }

                    > .price,
                    .quantity {
                        display: flex;
                        flex: 1;
                        font-weight: 700;
                    }
                }
            }

            > .right-side {
                flex: 0 0 300px;
            }
        }
    }
}

@media print {
    .container {
        :global(.card-header) {
            font-size: 16px;

            > span:first-child {
                flex: 0 0 250px;
            }

            > span:nth-child(2),
            span:nth-child(3) {
                display: block;
                flex: 0 0 150px;
            }

            > span:nth-child(3) {
                margin-right: 20px;
            }

            > span:nth-child(4) {
                display: block;
                flex: 0 0 200px;
                padding-left: 10px;
            }
        }

        :global(.card-body) {
            display: flex;

            > .mobile-shipping {
                display: none;
            }

            > .item-group {
                flex: 3 1;
                margin-right: 20px;

                > .item {
                    display: flex;
                    padding: 10px 0px 10px 10px;

                    &:first-child {
                        padding-top: 0px;
                    }

                    &:last-child {
                        padding-bottom: 0px;
                    }

                    > div:first-child {
                        flex: 0 0 250px;

                        > div:last-child {
                            > div:first-child {
                                max-width: 150px;
                            }

                            div:nth-child(2),
                            div:last-child {
                                display: none;
                            }
                        }
                    }

                    > .price,
                    .quantity {
                        display: flex;
                        flex: 0 0 150px;
                        font-weight: 700;
                    }
                }
            }

            > .right-side {
                display: flex;
                flex-direction: column;
                flex: 0 0 200px;
            }
        }
    }
}

@media print {
    .container {
        :global(.card-header) {
            padding: 0px;
        }
        :global(.card-body) {
            padding: 10px 0px;
        }
    }
}
