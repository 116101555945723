@import "../../scss/_variables.scss";

.container {
    width: 100%;

    > a:first-child {
        color: $gray-1;
        font-size: 14px;
        font-weight: 500;
        padding: 10px 10px 10px 0px;
        display: flex;
        align-items: center;

        div {
            transform: rotate(180deg);
            margin-right: 5px;
        }
    }

    > div > div {
        > p {
            font-size: 16px;
            line-height: 22px;
            font-weight: 700;
            margin-bottom: 0px;
        }

        .wrap {
            flex-wrap: wrap;

            a {
                margin-bottom: 10px;
            }
        }

        > div {
            display: flex;
            overflow-x: scroll;
            overflow-y: hidden;
            padding-bottom: 10px;

            button {
                align-self: baseline;
            }

            a {
                margin-right: 10px;

                div:first-child {
                    width: inherit;
                    //height: 75px;
                    border: 1px solid $gray-3;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        width: inherit;
                        height: inherit;
                        object-fit: contain;
                    }

                    p {
                        color: $gray-1;
                        font-size: 20px;
                        line-height: 25px;
                        text-align: center;
                        margin-bottom: 0px;
                    }
                }

                div:last-child {
                    height: 35.2px;
                    font-size: 12px;
                    text-align: center;
                    word-break: break-word;
                }
            }
        }
    }
}

@include media-lg {
    .container {
        display: none;
    }
}
