@import "../../scss/_variables.scss";

.container {
    .wrapper {
        display: flex;
        align-items: center;

        > div:first-child {
            flex: 1;
            margin-right: 10px;
        }
    }

    .wrapper:nth-child(2) {
        div:nth-child(2) {
            span {
                padding-right: 20px;
            }
        }
    }

    .zip-city-state {
        > div:first-child {
            display: flex;

            > div {
                flex: 1;

                &:first-child {
                    margin-right: 10px;
                }
            }
        }
    }
    /*    label:after {
        content: " *";
        color: $red-1;
        font-style: italic;
    }*/
    > div:last-child {
        label:after {
            margin-left: 5px;
            content: "In case of a shipping issue";
            color: $gray-2;
            font-style: italic;
            font-size: 12px;
        }
    }
}

@include media-md {
    .container {
        > div:nth-child(8) {
            margin-bottom: 20px;
        }

        .zip-city-state {
            display: flex;
            flex-direction: row;

            > div {
                flex: 1;
                margin-right: 15px;
                margin-bottom: 0px;

                &:last-child {
                    margin-right: 0px;
                }
            }
        }
    }
}
