@import "../../scss/variables";

.option-list {
    list-style-type: none;
    margin: 0;
    padding: 0;

    .option-value-checked {
        background-color: $gray-5;
    }

    .option-value-disabled {
        background-color: $gray-6;

        &:hover {
            background-color: $gray-6;
        }
    }

    .visible {
        visibility: visible;
    }

    .invisible {
        visibility: hidden;
    }

    > li {
        position: relative;
        display: block;
        border-bottom: 1px solid $gray-3; /* border between items*/

        &:last-child {
            /* no border on bottom/top */
            border-bottom: none;
        }

        &:hover {
            background-color: $gray-5;
        }

        > label {
            position: relative;
            cursor: pointer;
            display: block;

            > * {
                /* cursor on any child elements relates to the label, which is tied to the input -- this means you can't use an A element within a radio description */
                pointer-events: none;
            }

            > div {
                /* unselected color */
                min-height: 60px;
                padding: 10px 10px 10px 45px;
                background-color: transparent;
                min-height: 22px;
            }
            /*&:hover > div { /* hover color 
				background-color: $gray-5;
			}*/
            > input {
                /* hide the original radio */
                position: absolute;
                visibility: visible;
                opacity: 0;

                &:checked + div {
                    /* checked color */
                    background-color: $gray-5;
                }

                &:active + div {
                    /* mousedown color */
                    background-color: $gray-4;
                }

                + *::before {
                    /* empty radio (white circle) */
                    position: absolute;
                    left: 15px;
                    top: 10px;
                    display: block;
                    width: 20px;
                    height: 20px;
                    content: "";
                    user-select: none;
                    background-color: white;
                    border: 1px solid $gray-2;
                    border-radius: 50%;
                }

                &:checked + *::before {
                    /* add dot inside circle */
                    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNNyAxM0MxMC4zMTM3IDEzIDEzIDEwLjMxMzcgMTMgN0MxMyAzLjY4NjI5IDEwLjMxMzcgMSA3IDFDMy42ODYyOSAxIDEgMy42ODYyOSAxIDdDMSAxMC4zMTM3IDMuNjg2MjkgMTMgNyAxM1oiIGZpbGw9IiNGRkU4MUEiIHN0cm9rZT0iIzI0MjcyQyIvPg0KPC9zdmc+DQo=);
                    background-position: center;
                    background-repeat: no-repeat;
                    border: 1px solid $gray-1;
                }

                &:hover + *::before {
                    border: 1px solid $gray-1;
                    box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.5);
                }

                &:focus + *::before {
                    border: 1px solid $yellow-2;
                    box-shadow: 0px 0px 6px $yellow-1;
                }

                &:disabled {
                    + div {
                        cursor: default;
                        color: $gray-3;
                        background-color: $gray-6;

                        h1 {
                            color: $gray-3;
                        }

                        &::before {
                            border: 1px solid $gray-4;
                            background-color: $gray-5;
                            background-image: none;
                            box-shadow: none;
                        }

                        &:active + div {
                            /* mousedown color */
                        }
                    }
                }
            }
        }

        > div {
            /* error box */
            padding: 10px;
            background-color: transparent;

            .cvv-offset {
                position: absolute;
                top: 12px;
                right: 20px;
            }
        }
    }

    p, /* p for normal text */
    h1 {
        margin: 0;
        padding: 0;
        font-size: 14px;
        font-family: Roboto;
        font-weight: normal;
    }

    h1 {
        /* h1 for green text */
        font-weight: bold;
        color: green;
    }

    .orange {
        h1 {
            color: $orange;
        }
    }
}

.button-container {
    cursor: pointer;

    > button:first-of-type {
        margin-right: 10px;
    }
}

@include media-md {
    .option-list {
        > li {
            > div {
                padding: 15px;

                .cvv-offset {
                    right: 30%;
                }
            }
        }
    }

    .button-container {
        > button:first-of-type {
            margin-bottom: 10px;
            margin-right: 0;
        }
    }
}

//Edge browser moves yellow dot off center at 1200px
@include media-xl {
    @supports (-ms-ime-align: auto) {
        .option-list {
            > li {
                > label {
                    > input {
                        &:checked + *::before {
                            /* add dot inside circle */
                            //edge translates background-position: center to 50%
                            background-position: 40%;
                        }
                    }
                }
            }
        }
    }
}
