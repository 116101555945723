@import "../../scss/_variables.scss";

.container {
    background-color: $gray-2;
    display: flex;
    align-items: center;
    margin: -10px -10px 0px -10px;
    padding: 20px 15px;
    height: 60px;
    margin-bottom: 10px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);

    div {
        font-size: 22px;
        font-weight: bold;
        line-height: 24px;
        color: $white;
        flex: 1;
    }

    > span {
        flex: 1;
    }

    .title {
        display: flex;
        justify-content: center;
        flex: 1 1 auto;
    }

    button {
        color: white;
        font-size: 14px;
        background: none;
        border: none;
        font-weight: bold;
    }

    a {
        color: white;
        font-size: 14px;
    }
}

@include media-md {
    .container {
        display: none;
        margin: -20px -20px 0px -20px;
        margin-bottom: 20px;
        padding: 30px 25px;
    }
}
