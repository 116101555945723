@import "../../scss/variables";

.image-details-container {
    display: flex;

    .item-name {
        font-size: 18px;
        font-weight: 600;
        line-height: 22px;
        margin-bottom: 4px;
        color: $blue-1;
    }

    .non-clickable {
        font-size: 18px;
        font-weight: 600;
        line-height: 22px;
        margin-bottom: 4px;
        color: $gray-1;
    }

    .details-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex: 1 0 0;
    }

    &.shopping-cart {
        flex-wrap: wrap;

        .price {
            color: $red-2;
        }

        .item-name {
            color: $blue-1;
        }
    }

    &.order-details {
        .item-name {
            color: $blue-1;
        }
    }

    &.orders-and-returns {
        .item-name {
            color: $blue-1;
        }
    }

    .image-container {
        flex-shrink: 0;
        flex-grow: 0;
        width: 90px;
        height: 90px;
        margin-right: 10px;
        display: flex;
        flex-direction: row;
        justify-content: center;

        img {
            align-self: center;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    .inputs {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }

    .price {
        font-size: 20px;
        line-height: 24px;
        font-weight: 700;
        margin-bottom: 5px;
    }

    .search-result-price {
        display: flex;
        align-items: flex-end;

        .superscript {
            font-size: 20px;
            line-height: 24px;
            font-weight: bold;
            margin-bottom: 5px;
            display: flex;
            align-items: flex-end;

            span {
                font-size: 12px;
                margin-bottom: 2px;
            }
        }
    }

    span.vendor {
        color: $gray-1;
    }

    .line-reference {
        margin-top: 10px;
        width: 100%;
        display: flex;

        > div {
            /*input container*/
            margin-bottom: 0px;
            /*flex: 1 1 auto;*/
            margin-right: 10px;

            > div {
                /*input horizonal-label*/
                > div {
                    max-width: 159px;
                }
            }
        }

        label {
            /*flex: 0 0 80px;*/
            flex: 0 0 90px;
        }

        > div > div > div div {
            display: none;
        }

        &.set-reference {
            font-size: 14px;
            align-items: center;

            label {
                flex: unset;
                width: 90px;
                margin-right: 10px;
            }

            div {
                margin-right: 10px;
                flex: 1 1 90px;
                max-width: 135px;
                font-weight: 500;
            }
        }
    }

    .default-field {
        //for the rest of the fields
        font-size: 14px;
        color: $gray-1;
        font-weight: 400;

        > span {
            margin-left: 5px;
        }

        &.brand {
            font-size: 14px;
            line-height: 20px;
            color: $gray-1;
        }

        &.card-inputs {
            display: flex;
            align-items: center;
            margin-top: 10px;
            width: 100%;

            .quantity {
                display: none;
            }

            .qty {
                display: none;
            }

            span {
                flex: 0 0 80px;
            }

            > div:first-of-type {
                margin-bottom: 0px;
                margin-right: 10px;
                width: 90px;
                /*> div {
                    display: none;
                }*/
            }

            button:first-of-type {
                margin-right: 10px;
                flex: 1 1 90px;
                max-width: 135px;
            }
        }
    }

    .interchange {
        font-size: 14px;

        > span {
            background-color: $yellow-1;
        }
    }
}

@include media-md {
    .image-details-container {
        display: flex;
        /*align-items: center;*/
        /*flex-wrap: wrap;*/
        .image-container {
            width: 100px;
            height: 100px;
            margin-right: 20px;
            display: flex;
            flex-direction: row;
            justify-content: center;
        }

        .line-reference {
            > div {
                flex: 1 1 auto;

                > div {
                    > div {
                        max-width: none;
                    }
                }
            }

            label {
                flex: 0 0 70px;
            }

            &.set-reference {
                label {
                    width: 135px;
                }
            }
        }

        .default-field {
            /*&.tablet-hide {
                display: none;
            }*/
            &.card-inputs {
                .qty {
                    display: block;
                    flex: 0 0 35px;
                    margin-left: 0px;
                }

                button:first-of-type {
                    flex: 1;
                    max-width: 135px;
                }
            }
        }
        /*.price {
            &.tablet-hide {
                display: none;
            }
        }*/
    }
}

@include media-md {
    .image-details-container {
        .default-field {
            &.card-inputs {
                flex: 0 0 416px;
            }
        }

        .line-reference {
            flex: 0 0 416px;
        }
    }
}

@include media-lg {
    .image-details-container {
        .default-field {
            &.card-inputs {
                flex: 0 0 460px;

                .qty {
                    display: none;
                }

                .quantity {
                    display: block;
                    margin-left: 0px;
                }
            }
        }

        .line-reference {
            flex: 0 0 460px;

            &.set-reference {
                label {
                    width: 70px;
                }

                div {
                    max-width: 240px;
                }
            }
        }
    }
}

@include media-xl {
    .thank-you-hide {
        display: none;
    }
}
