@import "../../scss/variables";

.form-control {
    ~ div.validation-container {
        color: $red-1;
        font-size: 14px;
        font-style: italic;
        line-height: 20px;
        visibility: hidden;
    }
}

.was-validated {
    .form-control:invalid,
    .form-control.is-invalid {
        ~ div.validation-container {
            visibility: visible;
        }
    }

    .form-control {
        ~ div.validation-container.validation-force-display {
            visibility: visible;
        }
    }
}
