@import "../../scss/variables";

.homepagecategories > h3 ~ div > a,
.categorycard {
    min-height: 160px;
    width: 110px;
    text-align: center;
    border-radius: 2px;
    color: $gray-1;

    &:hover {
        color: $gray-1;
    }

    &:hover:not(.carousel) {
        background-color: $yellow-1;
        box-shadow: 0px 2px 4px rgba(36, 39, 44, 0.3);

        > div:first-child {
            border: 1px solid $gray-3;
            background-color: white;
        }
    }

    > div:first-child {
        margin: 9px;
        border: 1px solid transparent;
        width: 92px;
        height: 92px;
        > img {
            width: inherit;
            height: inherit;
            object-fit: contain;
        }
    }

    > div:last-child {
        display: inline-block;
        justify-content: center;
        padding: 0px 10px 0px 10px;
        width: 110px;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 14px;
    }
}
