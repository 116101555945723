@import "../../scss/variables";

.card {
    border-radius: 4px;
    box-shadow: $card-shadow;
    background-color: $white;
    margin-bottom: 15px;

    &.no-shadow {
        box-shadow: none;
    }
}

.card-header {
    padding: 10px;
    margin-bottom: 0;
    background-color: $gray-4;
    border-bottom: 1px solid $gray-3;
    font-size: 18px;
    color: $gray-1;
    font-weight: bold;
    display: flex;
    justify-content: space-between;

    &.align-left {
        > * {
            width: 100%;
            justify-content: flex-start;
        }
    }

    &:first-child {
        border-radius: 4px 4px 0 0;
    }
}

.card-body {
    padding: 10px;
}

@include media-md {
    .card {
        margin-bottom: 30px;
    }
    .card-header {
        padding: 10px 20px;
    }

    .card-body {
        padding: 20px;
    }
}

@media print {
    .card {
        border: none;
        box-shadow: none;
        margin-bottom: 0px;
    }
}
