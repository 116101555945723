@import "../../scss/variables";
@import "../../scss/images";

.container {
    width: 100%;
    margin-right: 30px;

    &.hidearrow a > span {
        display: none !important;
    }

    div {
        display: flex;
        flex-direction: column;

        a {
            color: $gray-1;
            font-size: 14px;
            font-weight: 500;
            padding: 5px 0px 5px 17px;
            border-left: 3px solid transparent;
            display: block;

            &:hover {
                background-color: $gray-4;
            }

            > span {
                display: inline-block;
                width: 19px;
                height: 11px;
                margin: 0;
                background-position: center;
                background-repeat: no-repeat;
                @include image-FilledRightArrow();
            }

            &:global(.sidebar-nav-selected) {
                background-color: $gray-4;
                border-left: 3px solid $blue-1;
            }

            &:global(.sidebar-nav-selected) > span,
            &:global(.sidebar-nav-expanded) > span {
                @include image-FilledDownArrow();
            }
        }

        :global(.sidebar-second-level) {
            a {
                padding: 5px 0 5px 37px;
            }
        }
    }
}
