@import "../../scss/_variables.scss";

.container {
    height: 46px;
    background-color: $gray-4;
    display: flex;
    justify-content: space-between;
    padding: 20px;
    align-items: center;
    border-radius: 4px 4px 0 0;
}

.title {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
}

.close {
    background-color: transparent;
    border: none;
    background-position: center;
    background-repeat: no-repeat;
    height: 24px;
    width: 24px;
    background-image: url("data:image/svg+xml,<svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'><rect width='18.8561' height='3.77123' rx='0.5' transform='matrix(0.707106 -0.707108 0.707106 0.707108 0 13.334)' fill='%238A93A3'/><rect width='3.77123' height='18.8561' rx='0.5' transform='matrix(0.707106 -0.707108 0.707106 0.707108 0 2.66602)' fill='%238A93A3'/></svg>");

    &:hover {
        background-image: url("data:image/svg+xml,<svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'><rect width='18.8561' height='3.77123' rx='0.5' transform='matrix(0.707106 -0.707108 0.707106 0.707108 0 13.334)' fill='black'/><rect width='3.77123' height='18.8561' rx='0.5' transform='matrix(0.707106 -0.707108 0.707106 0.707108 0 2.66602)' fill='black'/></svg>");
        cursor: pointer;
    }
}
