@import "../../scss/_variables.scss";

@include media-md {
    .BackButton,
    .mobileSearch {
        display: none;
    }
}

$boarderRadius: 20px;
// $boarderRadius: 12px;
.SearchBar {
    display: flex;
    box-shadow: $card-shadow;
    // border-radius: 12px;
    border-radius: $boarderRadius;

    .fitment-container {
        position: absolute;
    }
    //desktop

    //mobile
    @include media-not-md {
        .desktopSearch {
            display: none;
        }
    }
    > button {
        /* search button */
        background-color: white;
        // border-radius: 12px 0px 0px 12px;
        border-radius: $boarderRadius 0px 0px $boarderRadius;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml,<svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M12.1245 10.5801C12.915 9.48535 13.3794 8.14258 13.3794 6.68945C13.3794 2.99512 10.3843 0 6.68945 0C2.99512 0 0 2.99512 0 6.68945C0 10.3838 2.99512 13.3789 6.68945 13.3789C8.14355 13.3789 9.48682 12.9141 10.5825 12.126L16.4668 17.999L18 16.4658L12.1245 10.5801ZM2.05859 6.69141C2.05859 9.24902 4.13232 11.3223 6.68994 11.3223C9.24756 11.3223 11.3213 9.24902 11.3213 6.69141C11.3213 4.13379 9.24756 2.06055 6.68994 2.06055C4.13232 2.06055 2.05859 4.13379 2.05859 6.69141Z' fill='%2324272C'/></svg>");
    }

    button {
        min-height: 40px;
        min-width: 40px;
        max-width: 40px;
        border: none;
        box-sizing: border-box;
    }

    > input {
        flex: 1;
        width: 100%;
        height: 40px;
        box-sizing: border-box;
        border: none;
        // border-radius: 0px 12px 12px 0px;
        border-radius: 0px $boarderRadius $boarderRadius 0px;
        padding: 0px 10px;
        background-color: white;
        outline: none;
        -webkit-appearance: none;
    }
}
