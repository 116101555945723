@import "../../scss/_variables.scss";

.rating-container {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;
    //margin-bottom: 10px;

    img {
        vertical-align: top;
    }

    &.selectable img:hover,
    &.selectable img:hover ~ img {
        background-image: url("data:image/svg+xml,<svg width='20' height='19' viewBox='0 0 20 19' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M10 14.216L14.944 17.2L13.632 11.576L18 7.792L12.248 7.304L10 2L7.752 7.304L2 7.792L6.368 11.576L5.056 17.2L10 14.216Z' fill='%23FFE81A' stroke='%23EEDA2B'/></svg>");
        vertical-align: top;
        background-size: 32px 32px;
    }

    &.selectable:hover img {
        //make the src star-outline here
    }

    &.selectable img:hover {
        cursor: pointer;
    }

    .large {
        width: 32px;
        height: 32px;
    }

    .filled {
        height: 25px;
        width: 27px;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml,<svg width='100%' height='100%' viewBox='0 0 20 19' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M10 14.216L14.944 17.2L13.632 11.576L18 7.792L12.248 7.304L10 2L7.752 7.304L2 7.792L6.368 11.576L5.056 17.2L10 14.216Z' fill='%23FFE81A' stroke='%23EEDA2B'/></svg>");

        &.large {
            width: 32px;
            height: 32px;
        }
    }

    .half {
        height: 25px;
        width: 27px;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml,<svg width='100%' height='100%' viewBox='0 0 20 19' fill='none' xmlns='http://www.w3.org/2000/svg'><g clip-path='url(%23clip0)'><path d='M10 14.216L14.944 17.2L13.632 11.576L18 7.792L12.248 7.304L10 2L7.752 7.304L2 7.792L6.368 11.576L5.056 17.2L10 14.216Z' fill='%23FFE81A' stroke='%23EEDA2B'/></g><g clip-path='url(%23clip1)'><path d='M10 14.216L14.944 17.2L13.632 11.576L18 7.792L12.248 7.304L10 2L7.752 7.304L2 7.792L6.368 11.576L5.056 17.2L10 14.216Z' stroke='%23C8CBD7'/></g><defs><clipPath id='clip0'><rect width='10' height='19' fill='white'/></clipPath><clipPath id='clip1'><rect width='10' height='19' fill='white' transform='translate(10)'/></clipPath></defs></svg>");

        &.large {
            width: 32px;
            height: 32px;
        }
    }

    .outline {
        height: 25px;
        width: 27px;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml,<svg width='100%' height='100%' viewBox='0 0 20 19' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M10 14.216L14.944 17.2L13.632 11.576L18 7.792L12.248 7.304L10 2L7.752 7.304L2 7.792L6.368 11.576L5.056 17.2L10 14.216Z' stroke='%23C8CBD7'/></svg>");

        &.large {
            width: 32px;
            height: 32px;
        }
    }

    .text {
        width: 100%;
        font-size: 14px;
        line-height: 20px;
        color: $gray-1;
    }
}
