.container {
    display: flex;
    padding: 20px;

    > div {
        margin-right: 20px;
    }

    > div:last-child {
        margin-right: 0;
    }
}
