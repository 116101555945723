@import "../../scss/variables";

.was-validated {
    input[type="radio"]:invalid ~ label::before {
    }
}

.container {
    position: relative;
    min-height: 1.5rem;
    padding-left: 20px;

    &.inline {
        display: inline-flex;

        + .container {
            margin-left: 10px;
        }
    }

    .radio {
        padding: 0;
        position: absolute;
        opacity: 0;
        z-index: -1;

        &:checked ~ label::before {
            background-color: $white;
            border: 1px solid $gray-1;
            background-image: url("data:image/svg+xml,<svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M7 13C10.3137 13 13 10.3137 13 7C13 3.68629 10.3137 1 7 1C3.68629 1 1 3.68629 1 7C1 10.3137 3.68629 13 7 13Z' fill='%23FFE81A' stroke='%2324272C'/></svg>");
            background-position: center;
            background-repeat: no-repeat;
        }

        &:focus + label::before {
            background-color: $white;
            border: 1px solid $yellow-2;
            box-shadow: 0px 0px 6px $yellow-1;
        }

        &:disabled {
            ~ label {
                cursor: default;
                color: $gray-3;

                &::before {
                    border: 1px solid $gray-5;
                    background-color: $gray-4;
                }

                &:hover::before {
                    box-shadow: none;
                }
            }
        }
    }

    label {
        display: inline-block;
        align-items: center;
        margin-bottom: 0;
        font-size: 14px;
        line-height: 20px;
        cursor: pointer;
        padding-left: 5px;

        &::before {
            position: absolute;
            //top: 0.15rem;
            left: 0;
            display: block;
            width: 20px;
            height: 20px;
            border: 3px solid $white;
            pointer-events: none;
            content: "";
            user-select: none;
            background-color: $white;
            border: 1px solid $gray-2;
            border-radius: 50%;
        }

        &::after {
            position: absolute;
            top: 0.15rem;
            left: 0;
            display: block;
            width: 20px;
            height: 20px;
            content: "";
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 50% 50%;
        }

        &:hover::before {
            border: 1px solid $gray-1;
            box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.5);
        }
    }
}
