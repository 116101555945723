@import "../../scss/_variables.scss";

.h1b {
    font-weight: bold;
    font-size: 24px;
}

.h1r {
    font-weight: normal;
    font-size: 24px;
}

.h2b {
    font-weight: bold;
    font-size: 22px;
}

.h3b {
    font-weight: bold;
    font-size: 20px;
}

.h4b {
    font-weight: bold;
    font-size: 18px;
}

.h4m {
    font-weight: 500;
    font-size: 18px;
}

.h4r {
    font-weight: 400;
    font-size: 18px;
}

.h5b {
    font-weight: bold;
    font-size: 16px;
}

.h5m {
    font-weight: 500;
    font-size: 16px;
}

.h5r {
    font-weight: normal;
    font-size: 16px;
}

.b1b {
    font-weight: bold;
    font-size: 14px;
}

.b1m {
    font-weight: 500;
    font-size: 14px;
}

.b1r {
    font-weight: normal;
    font-size: 14px;
}

.b2b {
    font-weight: bold;
    font-size: 12px;
}

.b2m {
    font-weight: 500;
    font-size: 12px;
}

.b2r {
    font-weight: normal;
    font-size: 12px;
}

.gray2 {
    color: $gray-2;
}

.mini-grey-ellipsis {
    font-size: 14px;
    color: #8a93a3;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
