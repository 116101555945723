@import "../../scss/variables";

.button-container {
    display: flex;

    &.vertical {
        flex-direction: column;

        > button {
            margin-bottom: 10px;
        }

        > button:last-child {
            margin-bottom: 0;
        }
    }

    &.horizontal {
        flex-direction: row;

        > button {
            margin-right: 10px;
        }

        > button:last-child {
            margin-right: 0;
        }
    }
}

// Desktop and Tablet
@include media-sm {
    .button-container {
        &.vertical {
            > button {
                margin-bottom: 15px;
            }
        }

        &.horizontal {
            > button {
                margin-right: 15px;
            }
        }
    }
}
