@import "../../scss/variables";

.container {
    .horizontal-label {
        display: flex;
        align-items: center;

        label {
            margin-right: 10px;
        }

        > div {
            display: flex;
            flex-direction: column;
            flex: 1;
        }
    }

    label {
        font-size: 14px;

        &.disabled {
            color: $gray-3;
        }
    }

    input {
        display: block;
        height: 36px;
        width: 100%;
        padding: 0.375rem 0.75rem;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.5;
        color: $gray-1;
        background-color: $white;
        background-clip: padding-box;
        border-radius: 0.25rem;
        border: 1px solid $gray-3;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

        &::placeholder {
            color: $gray-3;
        }

        &:hover {
            border: 1px solid $gray-1;
            box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.175);
        }

        &:focus {
            border: 1px solid $yellow-2;
            box-shadow: 0px 0px 3px 2px $yellow-1;
        }

        &:disabled {
            background-color: $gray-5;
            border: 1px solid $gray-4;
            box-shadow: none;
        }

        &:read-only {
            background-color: $gray-5;

            + div {
                display: none;
            }
        }

        &:read-only:hover {
            border: 1px solid $gray-3;
            background-color: $gray-5;
            box-shadow: none;
        }

        &:read-only:focus {
            border: 1px solid $gray-3;
            box-shadow: none;
        }
    }

    textarea {
        display: block;
        width: 100%;
        padding: 0.375rem 0.75rem;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.5;
        color: $gray-1;
        background-color: $white;
        background-clip: padding-box;
        border-radius: 0.25rem;
        border: 1px solid $gray-3;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

        &:hover {
            border: 1px solid $gray-1;
            box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.175);
        }

        &:focus {
            border: 1px solid $yellow-2;
            box-shadow: 0px 0px 3px 2px $yellow-1;
        }

        &:disabled {
            background-color: $gray-5;
            border: 1px solid $gray-4;
            box-shadow: none;
        }

        &:read-only {
            background-color: $gray-5;

            + div {
                display: none;
            }
        }

        &:read-only:hover {
            border: 1px solid $gray-3;
            background-color: $gray-5;
            box-shadow: none;
        }

        &:read-only:focus {
            border: 1px solid $gray-3;
            box-shadow: none;
        }
    }
}
