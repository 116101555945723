@import "../../scss/_variables.scss";

.overlay {
    background: rgba(0, 0, 0, 0.75);
    display: flex;
    align-items: flex-start;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 1000;
    padding: 1.2rem;
}

.modal {
    width: 100%;
    max-width: 790px;
    position: relative;
    background: #ffffff;
    background-clip: padding-box;
    box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.25);
    margin: 0 auto;
    border-radius: 4px;
}

.modalCenter {
    margin: auto;
}

.closeButton {
    position: absolute;
    top: 14px;
    right: 14px;
    border: none;
    padding: 0;
    background-color: transparent;
    display: flex;
}

.closeIcon {
}

.transitionEnter {
    opacity: 0.01;
}

.transitionEnterActive {
    opacity: 1;
    transition: opacity 250ms cubic-bezier(0.23, 1, 0.32, 1);
}

.transitionExit {
    opacity: 1;
}

.transitionExitActive {
    opacity: 0.01;
    transition: opacity 250ms cubic-bezier(0.23, 1, 0.32, 1);
}

.open {
    display: block;
}

.invisible {
    display: none;
}
