@import "../../scss/_variables.scss";

.container {
    button {
        background-color: transparent;
        border: none;
        padding: 0px;
        display: flex;
        align-items: center;
        height: 24px;
        border: 1px solid rgba(0, 0, 0, 0);

        &:focus {
            outline: none;

            > span {
                text-decoration: underline;
            }
        }

        .icon-minus {
            height: 4px;
            width: 14px;
            margin-right: 5px;
            background-image: url("data:image/svg+xml,<svg width='14' height='4' viewBox='0 0 16 4' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M15.9999 1.99984L-6.10352e-05 1.99984' stroke='%231072E5' stroke-width='3' stroke-linejoin='round'/></svg>");
        }

        .icon-plus {
            height: 14px;
            width: 14px;
            margin-right: 5px;
            background-image: url("data:image/svg+xml,<svg width='14' height='14' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M15.9999 7.99984L-6.10352e-05 7.99984' stroke='%231072E5' stroke-width='3' stroke-linejoin='round'/><path d='M7.99992 16L7.99992 -5.82819e-08' stroke='%231072E5' stroke-width='3' stroke-linejoin='round'/></svg>");
        }
    }

    .label {
        font-size: 14px;
        line-height: 20px;
    }
}

.title {
    color: $blue-1;
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    display: flex;

    img {
        margin-right: 10px;
    }
}
