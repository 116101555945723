@import "../../scss/_variables.scss";

@-moz-document url-prefix() {
    .cc-info,
    .exp-month,
    .exp-year,
    .cvv {
        padding: 0.375rem 0.75rem;
    }
}

:global(.braintree-input-styling) {
    /*
    These styles are applied directly to the hosted <input> or <select> field

    Supported CSS properties are:
    appearance
    color
    direction
    font-family
    font-size-adjust
    font-size
    font-stretch
    font-style
    font-variant-alternates
    font-variant-caps
    font-variant-east-asian
    font-variant-ligatures
    font-variant-numeric
    font-variant
    font-weight
    font
    letter-spacing
    line-height
    opacity
    outline
    margin
    padding
    text-shadow
    transition
    -moz-appearance
    -moz-osx-font-smoothing
    -moz-tap-highlight-color
    -moz-transition
    -webkit-appearance
    -webkit-font-smoothing
    -webkit-tap-highlight-color
    -webkit-transition

    Note that fonts will only be supported if handled directly by the browser;
    fonts cannot be imported via @font-face or otherwise

*/
    color: $gray-1;
    font-size: 14px;
    font-family: Roboto, sans-serif;
    font-weight: 400;
    padding: 0.375rem 0.75rem;
    /*

    media queries apply only when the hosted field is initialized, so
    it can probably be used for dark mode, but not printing or resizing
    
    
    additional classes can be defined and applied at runtime, as long as
    they only contain the supported css properties listed above. they
    have to be referenced in the useBraintree hook

*/
}

.cc-info,
.exp-month,
.exp-year,
.cvv {
    display: block;
    position: relative;
    height: 36px;
    border: 1px solid $gray-3;
    width: 100%;
    text-indent: 15px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: $gray-1;
    background-color: $white;
    background-clip: border-box;
    border-radius: 0.25rem;
    border: 1px solid $gray-3;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:global(.braintree-hosted-fields-focused):not(.disabled) {
        /* focused regular */
        border: 1px solid $yellow-2;
        box-shadow: 0px 0px 3px 2px $yellow-1;
    }

    &:hover:not(:global(.braintree-hosted-fields-focused)):not(.disabled) {
        border: 1px solid $gray-1;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.175);
    }

    &.disabled {
        background-color: $gray-5;
        border-color: $gray-4;
    }

    + .error {
        height: 20px;

        > span {
            display: none;
            color: $red-1;
            font-size: 14px;
            font-style: italic;
            line-height: 20px;
        }
    }
}

:global(.was-validated) {
    /* not fully valid */
    .cc-info,
    .exp-month,
    .exp-year,
    .cvv {
        &:global(.braintree-hosted-fields-not-valid) {
            border: 1px solid $red-1;

            &:global(.braintree-hosted-fields-focused):not(.disabled) {
                /* focused */
                border: 1px solid $red-1;
                box-shadow: 0 0 3px 2px hsl(6, 65%, 82%);
            }

            &:hover:not(:global(.braintree-hosted-fields-focused)):not(
                    .disabled
                ) {
                /* hover */
                border: 1px solid $red-1;
            }

            + .error {
                > span {
                    display: block;
                }
            }
        }
    }
}

:not(:global(.was-validated)) {
    /* not potentially valid */
    .cc-info,
    .exp-month,
    .exp-year,
    .cvv {
        &:global(.braintree-hosted-fields-invalid) {
            border: 1px solid $red-1;

            &:global(.braintree-hosted-fields-focused):not(.disabled) {
                /* focused */
                border: 1px solid $red-1;
                box-shadow: 0 0 3px 2px hsl(6, 65%, 82%);
            }

            &:hover:not(:global(.braintree-hosted-fields-focused)):not(
                    .disabled
                ) {
                /* hover */
                border: 1px solid #ff6a00; //fix color
            }

            + .error {
                > span {
                    display: block;
                }
            }
        }
    }
}

.form {
    label {
        font-size: 14px;
    }

    label:last-child {
        &:after {
            content: " *";
            color: $red-1;
            font-style: italic;
        }
    }

    > div {
        > .second-row {
            display: flex;
            flex-direction: row;
            align-items: flex-end;

            .card-exp {
                display: flex;
                flex-direction: row;
                align-items: flex-end;
                margin-right: 10px;
                min-width: 112px;

                > span {
                    position: relative;
                    margin-right: 10px;
                    flex: 1;
                    margin-bottom: 0px;

                    > label {
                        position: absolute;
                        top: -20px;
                        width: max-content;
                    }
                }

                > div:last-child {
                    //display: flex;
                    flex: 1;
                }
            }
            /*        > .second-row {
            .card-exp {
                display: flex;
                flex-direction: column;

                > span {
                    margin-bottom: 5px;
                }

                > div:first-child {
                    margin-right: 20px;
                }

                > div:last-child {
                    display: flex;
                    flex-direction: column;
                    >label{
                        display: none;
                    }
                }
            }*/
            .cvv-icons {
                display: flex;
                flex-direction: column;

                > span {
                    display: flex;
                    align-items: center;

                    > div:first-child {
                        width: 98px;
                        margin-right: 10px;
                        margin-bottom: 0px;
                    }

                    > .align-content {
                        display: flex;
                        align-items: center;
                        height: 36px;
                        margin-left: 0px;
                        margin-bottom: 20px;

                        :global(.image-creditcard) {
                            margin-right: 10px;
                        }
                    }
                }
            }
        }

        > div:nth-child(6) {
            label {
                &:after {
                    content: "For receipt and tracking number";
                    color: $gray-2;
                    font-size: 12px;
                    font-style: italic;
                    margin-left: 5px;
                }
            }
        }
    }

    > .tablet-buttons {
        display: none;
    }
}

@include media-md {
    .form {
        > div {
            > .second-row {
                display: flex;
                flex-direction: row;
                align-items: flex-end;

                .card-exp {
                    //display: flex;
                    flex-direction: row;
                    align-items: flex-end;
                    margin-right: 20px;

                    > span {
                        margin-right: 20px;
                        flex: 2;
                        margin-bottom: 0px;
                    }

                    > div:last-child {
                        //display: flex;
                        flex: 1;
                    }
                }

                .cvv-icons > span > div:first-child > .align-content {
                    height: 27px;
                }
                /*
                .cvv-icons {
                    display: flex;
                    flex-direction: column;

                    > span {
                        display: flex;
                        align-items: center;

                        > div:first-child {
                            width: 98px;
                            margin-right: 20px;
                            margin-bottom: 0px;
                        }

                        > .align-content {
                            display: flex;
                            align-items: center;
                            height: 27px;
                            margin-left: 0px;

                            :global(.image-creditcard) {
                                margin-right: 10px;
                            }
                        }
                    }
                }
                */
            }
        }

        > .tablet-buttons {
            display: block;
        }
    }
}
