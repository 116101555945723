@import "../../../../scss/_variables.scss";

.container {
    padding: 20px 20px 0 20px;
    width: 100%;

    .green {
        color: $green;
    }

    .tracker {
        display: flex;
        justify-content: space-between;
        border-bottom: solid 1px $gray-3;
        margin: 0 -20px;

        .tracker-status-mobile-only {
            color: $green;
            font-weight: 500;
            font-size: 18px;
            line-height: 24px;
            margin: 0 0 0 20px;
        }

        .tracker-status {
            display: flex;
            flex-direction: row;
            padding-bottom: 20px;
            margin: 0 20px;

            > .status {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                margin-right: 15px;

                > img {
                    width: 24px;
                    height: 24px;
                }

                .span {
                    display: none;
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    .tracker-top {
        padding: 15px 0;
        border-bottom: solid 1px $gray-3;

        > span:first-child {
            font-weight: bold;
            font-size: 18px;
            display: flex;
            flex-direction: column;
        }

        > span:last-child {
            font-weight: bold;
            font-size: 14px;
            line-height: 20px;
            color: $green;
        }
    }

    .tracker-bottom {
        display: flex;
        flex-direction: column;
        padding-top: 20px;

        > span {
            margin-bottom: 5px;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
        }
    }

    .item-container {
        border-bottom: solid 1px $gray-3;
        padding: 20px 0;

        &:first-child {
            border-top: solid 1px $gray-3;
        }
    }

    .item-container:first-child {
        border-top: solid 1px $gray-3;
    }

    .box {
        width: 22.5px;
        height: 25px;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml,<svg width='100%' height='100%' viewBox='0 0 108 120' fill='none' xmlns='http://www.w3.org/2000/svg'><path id='Shape' fill-rule='evenodd' clip-rule='evenodd' d='M108 87C108 89.28 106.74 91.26 104.82 92.28L57.42 118.92C56.46 119.64 55.26 120 54 120C52.74 120 51.54 119.64 50.58 118.92L3.18 92.28C1.26 91.26 0 89.28 0 87V33C0 30.72 1.26 28.74 3.18 27.72L50.58 1.08C51.54 0.36 52.74 0 54 0C55.26 0 56.46 0.36 57.42 1.08L104.82 27.72C106.74 28.74 108 30.72 108 33V87ZM54 12.9L42.66 19.32L78 39.66L89.76 33L54 12.9ZM54 53.1L18.24 33L30.48 26.1L65.76 46.5L54 53.1ZM12 83.46L48 103.74V63.48L12 43.26V83.46ZM96 43.26V83.46L60 103.74V63.48L96 43.26Z' fill='%238A93A3'/></svg>");
    }

    .truck {
        width: 27px;
        height: 20px;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml,<svg width='100%' height='100%' viewBox='0 0 44 33' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M38 8.67267H32V0.835938H4C1.8 0.835938 0 2.5992 0 4.7543V26.3053H4C4 29.5576 6.68 32.1829 10 32.1829C13.32 32.1829 16 29.5576 16 26.3053H28C28 29.5576 30.68 32.1829 34 32.1829C37.32 32.1829 40 29.5576 40 26.3053H44V16.5094L38 8.67267ZM10 29.2441C8.34 29.2441 7 27.9314 7 26.3053C7 24.6792 8.34 23.3665 10 23.3665C11.66 23.3665 13 24.6792 13 26.3053C13 27.9314 11.66 29.2441 10 29.2441ZM37 11.6114L40.92 16.5094H32V11.6114H37ZM34 29.2441C32.34 29.2441 31 27.9314 31 26.3053C31 24.6792 32.34 23.3665 34 23.3665C35.66 23.3665 37 24.6792 37 26.3053C37 27.9314 35.66 29.2441 34 29.2441Z' fill='%238A93A3'/></svg>");
    }

    .delivered {
        width: 24px;
        height: 22.5px;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml,<svg width='100%' height='100%' viewBox='0 0 43 40' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M16 27.8481V40H6V23.7975H0L20 5.56962L25.5 10.6329L20.5 15.6962L16.5 11.5L11 17.0696L21.5 27.8481L32.5 16.7089L40 23.7975H34V40H24V27.8481H16Z' fill='%238A93A3'/><path fill-rule='evenodd' clip-rule='evenodd' d='M25.5 10.6329L20.5 15.6962L16.5 11.5L11 17.0696L21.5 27.8481L32.5 16.7089L42.5 6.58228L36 0L25.5 10.6329ZM16.5 14.9848L21 19.2405L36 4.05063L38.5 6.58228L21 24.3038L14 17.5165L16.5 14.9848Z' fill='%238A93A3'/></svg>");
    }

    .check {
        width: 24px;
        height: 24px;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml,<svg width='100%' height='100%' viewBox='0 0 28 27' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M2 14.1035L9.57938 23.3176L26 2' stroke='%23248122' stroke-width='4'/></svg>");
    }
}

@include media-sm {
    .container {
        .tracker {
            display: flex;

            .tracker-status-mobile-only {
                display: none;
            }

            .tracker-status {
                flex: 1;
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                margin: 0;

                > .status {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-between;
                    margin-right: 0;
                    height: 70px;

                    > img {
                        width: 45px;
                        height: 45px;
                        margin-bottom: 15px;
                    }

                    .span,
                    .green {
                        display: block;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 14px;
                        line-height: 18px;
                    }

                    .span {
                        color: $gray-2;
                    }

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }
}

@include media-md {
    .container {
        .box {
            width: 36px;
            height: 40px;
        }

        .truck {
            width: 44px;
            height: 32px;
            margin-top: 5px;
        }

        .delivered {
            width: 42.5px;
            height: 40px;
            margin-left: 5px;
        }

        .check {
            width: 30px;
            height: 30px;
        }
    }
}

@include media-xl {
}
