@import "../../scss/_variables.scss";

.oos {
    color: $red-1;
}

.ins {
    color: $green;
}

.uks,
.bts {
    color: #df6c19;
}
