@import "../../scss/variables";

.container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: -2px;
    margin-bottom: -2px;

    > * {
        margin-top: 2px;
        margin-bottom: 2px;
    }

    .clear-all-btn {
        font-size: 12px;
    }

    > span {
        display: flex;
        align-items: center;
    }

    :global(.image-breadcrumbarrow) {
        display: inline-block;
        margin-right: 15px;
    }

    a {
        &:hover {
            text-decoration: underline;
        }

        color: $gray-1;
    }

    a,
    .secondary {
        margin-right: 15px;
        font-size: 12px;
        line-height: 16px;
    }

    .secondary {
        color: $gray-2;
    }

    .filter {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 15px;

        > div {
            font-size: 12px;
        }
    }

    :global(.image-closefilter) {
        background-color: transparent;
        padding: 0px;
        border: none;
        box-shadow: none;
        margin-right: 5px;
    }
}

@include media-not-md {
    .container {
        .all-categories,
        .grandparent-category,
        .parent-category > .arrow,
        product-name {
            display: none !important;
        }
    }
}
