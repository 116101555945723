@import "../../scss/_variables.scss";

.divider {
    width: 100%;
    background-color: $gray-3;
    height: 1px;

    &.vertical {
        width: 1px;
        background-color: $gray-3;
        height: 100%;
    }

    &.w-0 {
        width: initial;
    }
}
