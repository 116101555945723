﻿/*_____________COLORS_____________*/
$blue-1: #1072e5; //links
$blue-2: #165aaa; //active state
$green: #248122; //stock and ship date
$gray-1: #24272c; ///text
$gray-2: #8a93a3; //dark header
$gray-3: #c8cbd7; //header lines and input-border-default
$gray-4: #e4e8ee; //card headers and disabled buttons
$gray-5: #edf0f4; //hover highlight
$gray-6: #f8f9fa; //background (for desktop background)
$orange: #cd8919; //order cancelled
$red-1: #cc0a3b; //errors
$red-2: #ac2233; //prices
$visited-link: #551a8b; //visited links
$white: #ffffff; // background for cards
$yellow-1: #ffe81a;
$yellow-2: #eeda2b;
$yellow-3: #dfcc2c;

/*_____________EFFECT STYLES_____________*/
$button-shadow: 0 2px 4px rgba(0, 0, 0, 0.55); //box-shadow
$card-shadow: 0 2px 4px rgba(0, 0, 0, 0.3); //box-shadow

/*_____________SIZES_____________*/
$xs-max-width: 575.98px;
$sm-min-width: 576px;
$sm-max-width: 767.98px;
$md-min-width: 768px; //md includes all printed content
$md-max-width: 991.98px;
$lg-min-width: 992px;
$lg-max-width: 1199.98px;
$xl-min-width: 1200px;
$xl-max-width: 1535.98px;
$xxl-min-width: 1536px;

// see below link for more ideas
// https://css-tricks.com/approaches-media-queries-sass/

// this are simple queries that are size-or-larger queries
// printed content always renders at md size

@mixin media-xs {
    @content;
}

@mixin media-sm {
    @media print, (min-width: $sm-min-width) {
        @content;
    }
}

@mixin media-md {
    @media print, (min-width: $md-min-width) {
        @content;
    }
}

@mixin media-lg {
    @media screen and (min-width: $lg-min-width) {
        @content;
    }
}

@mixin media-xl {
    @media screen and (min-width: $xl-min-width) {
        @content;
    }
}

@mixin media-xxl {
    @media screen and (min-width: $xxl-min-width) {
        @content;
    }
}

@mixin media-not-sm {
    @media screen and (max-width: $xs-max-width) {
        @content;
    }
}

@mixin media-not-md {
    @media screen and (max-width: $sm-max-width) {
        @content;
    }
}

@mixin media-not-lg {
    @media print, (max-width: $md-max-width) {
        @content;
    }
}

@mixin media-not-xl {
    @media print, (max-width: $lg-max-width) {
        @content;
    }
}

@mixin media-not-xxl {
    @media print, (max-width: $xl-max-width) {
        @content;
    }
}
