.shake {
    animation: shakeAnimation 0.25s linear;
}

@keyframes shakeAnimation {
    0%,
    100% {
        transform: translateX(0);
    }
    25%,
    75% {
        transform: translateX(-10px);
    }
    50% {
        transform: translateX(10px);
    }
}
