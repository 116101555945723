// REQUIRED
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

//OPTIONAL
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/forms";

//VARIABLES
@import "variables";

.hide {
    display: none !important;
}

@include media-sm {
    .hide-sm {
        display: none !important;
    }
}
@include media-md {
    .hide-md {
        display: none !important;
    }
}
@include media-lg {
    .hide-lg {
        display: none !important;
    }
}
@include media-xl {
    .hide-xl {
        display: none !important;
    }
}
@include media-xxl {
    .hide-xxl {
        display: none !important;
    }
}
@include media-not-sm {
    .show-sm {
        display: none !important;
    }
}
@include media-not-md {
    .show-md {
        display: none !important;
    }
}
@include media-not-lg {
    .show-lg {
        display: none !important;
    }
}
@include media-not-xl {
    .show-xl {
        display: none !important;
    }
}
@include media-not-xxl {
    .show-xxl {
        display: none !important;
    }
}

label {
    margin-bottom: 0px;
    line-height: 20px;
}

body {
    font-family: "Roboto", sans-serif;
    background-color: $gray-6;
}

pre {
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;
    background-color: $gray-5;
    overflow: auto;
    border: none;
    font-family: monospace;
    font-size: 15px;
    line-height: 1.6;
    margin-bottom: 1.6em;
    max-width: 100%;
    padding: 1em 1.5em;
}

.hn {
    display: flex;
    width: 2px;
    height: 5px;
    position: fixed;
    z-index: 100;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.02);
    cursor: crosshair;
}

code {
    background-color: $gray-5;
}

span.loading {
    color: $gray-4;
    background-color: $gray-4;
}

a:hover {
    text-decoration: none;
}

.was-validated {
    .form-control:valid,
    .form-control.is-valid {
        background-image: none;
        border-color: $gray-3;

        &:hover {
            border-color: $gray-1;
        }

        &:focus {
            border-color: $yellow-2;
            box-shadow: 0px 0px 3px 2px $yellow-1;
        }
    }

    .form-control:invalid,
    .form-control.is-invalid {
        background-image: none;
        border-color: $red-1;

        &.remove-required + label::before {
            border: 1px solid $gray-2;
            box-shadow: none;
        }

        &.remove-required {
            &:hover {
                border-color: $gray-1;
            }
        }

        &:hover {
            border-color: $red-2;
        }

        &:hover + label::before {
            border-color: $red-2;
            box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.175);
        }

        &:focus {
            border-color: $red-1;
            box-shadow: 0 0 3px 2px hsl(6, 65%, 82%);
        }

        &:focus + label::before {
            border-color: $red-1;
            box-shadow: 0 0 6px $red-1;
        }

        + label::before {
            border: 1px solid $red-1;
        }
    }

    select.form-control:invalid,
    select.form-control.is-invalid {
        background-size: initial;
        padding: 0.375rem 0.75rem;
        background-position: right 10px top 50%;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml,<svg width='12' height='6' viewBox='0 0 12 6' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M12 7.62939e-06L6.00016 6.00001L0 7.62939e-06' fill='%231072E5'/></svg>");
    }

    select.form-control:valid,
    select.form-control.is-invalid {
        background-size: initial;
        padding: 0.375rem 0.75rem;
        background-position: right 10px top 50%;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml,<svg width='12' height='6' viewBox='0 0 12 6' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M12 7.62939e-06L6.00016 6.00001L0 7.62939e-06' fill='%231072E5'/></svg>");
    }
}

/* CSS */
.apple-pay-button {
    display: inline-block;
    -webkit-appearance: -apple-pay-button;
    -apple-pay-button-type: buy; /* Use any supported button type. */
    height: 40px;
    width: 100%;
}

.apple-pay-button-black {
    -apple-pay-button-style: black;
}

.apple-pay-button-white {
    -apple-pay-button-style: white;
}

.apple-pay-button-white-with-line {
    -apple-pay-button-style: white-outline;
}
