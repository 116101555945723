.spacer {
    display: flex;

    &.vertical {
        flex-direction: column;

        > * {
            margin-bottom: 15px;
        }

        &.m-0 > * {
            margin-bottom: 0;
        }

        &.m-5 > * {
            margin-bottom: 5px;
        }

        &.m-10 > * {
            margin-bottom: 10px;
        }

        &.m-15 > * {
            margin-bottom: 15px;
        }

        &.m-20 > * {
            margin-bottom: 20px;
        }

        &.m-25 > * {
            margin-bottom: 25px;
        }

        &.m-30 > * {
            margin-bottom: 30px;
        }

        &.m-35 > * {
            margin-bottom: 35px;
        }

        &.m-40 > * {
            margin-bottom: 40px;
        }

        > *:last-child {
            margin-bottom: 0;
        }
    }

    &.horizontal {
        > * {
            margin-right: 15px;
        }

        &.m-0 > * {
            margin-right: 0;
        }

        &.m-5 > * {
            margin-right: 5px;
        }

        &.m-10 > * {
            margin-right: 10px;
        }

        &.m-15 > * {
            margin-right: 15px;
        }

        &.m-20 > * {
            margin-right: 20px;
        }

        &.m-25 > * {
            margin-right: 25px;
        }

        &.m-30 > * {
            margin-right: 30px;
        }

        &.m-35 > * {
            margin-right: 35px;
        }

        &.m-40 > * {
            margin-right: 40px;
        }

        > *:last-child {
            margin: 0 0 0 0;
        }
    }
}
