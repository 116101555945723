.row {
    display: flex;

    &.make-header {
        background-color: #e4e8ee;
        font-weight: bold;
    }

    &.wrap {
        flex-wrap: wrap;
    }

    &.justify-content-start {
        justify-content: flex-start;
    }

    &.justify-content-end {
        justify-content: flex-end;
    }

    &.justify-content-between {
        justify-content: space-between;
    }

    &.justify-content-center {
        justify-content: center;
    }

    &.align-items-start {
        align-items: flex-start;
    }

    &.align-items-end {
        align-items: flex-end;
    }

    &.align-items-center {
        align-items: center;
    }

    &.flex-1 {
        > * {
            flex: 1;
            margin-right: 15px;
        }

        > *:last-child {
            margin-right: 0;
        }
    }

    &.flex-2 {
        > * {
            flex: 2;
            margin-right: 15px;
        }

        > *:last-child {
            margin-right: 0;
        }
    }

    &.flex-3 {
        > * {
            flex: 3;
            margin-right: 15px;
        }

        > *:last-child {
            margin-right: 0;
        }
    }

    &.flex-4 {
        > * {
            flex: 4;
            margin-right: 15px;
        }

        > *:last-child {
            margin-right: 0;
        }
    }

    &.flex-5 {
        > * {
            flex: 5;
            margin-right: 15px;
        }

        > *:last-child {
            margin-right: 0;
        }
    }
}
