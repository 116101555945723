.flex-1 {
    flex: 1;
}

.flex-2 {
    flex: 2;
}

.flex-3 {
    flex: 3;
}

.flex-4 {
    flex: 4;
}

.flex-5 {
    flex: 5;
}

.flex-6 {
    flex: 6;
}

.flex-7 {
    flex: 7;
}

.flex-8 {
    flex: 8;
}

.flex-9 {
    flex: 9;
}

.flex-10 {
    flex: 10;
}

.d-flex {
    display: flex;
}

.d-none {
    display: none;
}

.justify-between {
    justify-content: space-between;
}

.justify-end {
    justify-content: flex-end;
}

.justify-center {
    justify-content: center;
}
.align-i-baseline {
    align-items: baseline;
}
.align-i-bottom {
    align-items: bottom;
}
.v-a-middle {
    vertical-align: middle;
}
.v-a-bottom {
    vertical-align: bottom;
}
