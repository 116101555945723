@import "../../../scss/_variables.scss";
@import "../../../scss/images.scss";

$boarderRadius: 20px;

//mobile
@include media-not-md {
    .mobilehide {
        .container,
        .BackButton {
            display: none;
        }
    }

    .mobileShow {
        top: 0;
        left: 0;
        z-index: 5;
        position: fixed;
        width: 100%;
        height: 100%;

        .container,
        .fullWidth,
        .dropdown {
            height: 100%;
        }

        .BarBody {
            // &:focus-within{
            //     border:red solid 10px;

            // }
            background-color: #8a93a3;
            height: 72px;
            display: flex;
            width: 100%;
            align-items: center;
            .SearchBar {
                height: 40px;
                // 100% button
                width: calc(100% - 40px);
                margin-right: 16px;
                > input {
                    padding-left: 0px;
                }
            }
            & > button {
                &.BackButton {
                    height: 28%;
                    @include image-LeftChevron(#465061);
                }
                background-color: transparent;
                border: none;
                height: 30px;
                width: 20px;
                margin-left: 10px;
                margin-right: 10px;
            }
        }
        .container {
            border-top: 3px solid #ffe81a;

            .fullWidth {
                .item {
                    div {
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                    }
                }
            }
        }
    }
}

//desktop
@include media-md {
    .BarBody {
        &:focus-within {
            border-bottom-right-radius: 0px;
            border-bottom-left-radius: 0px;
            .SearchBar {
                border-bottom-right-radius: 0px;
                border-bottom-left-radius: 0px;
            }
        }
    }

    .desktopHide,
    .BackButton,
    .mobileSearch {
        display: none;
    }

    .container:not(.desktopHide):focus,
    div:focus-within ~ .container:not(.desktopHide) {
        display: block;
    }

    .wrapper:focus,
    .wrapper:active {
        .container:not(.desktopHide) {
            display: block;
        }
    }

    .container {
        display: none;
        // position: absolute;
        // top: calc(70px - ((70px - 40px) / 2) - 10px);
        // left: 0;
        .fullWidth {
            .blank {
                flex: 1 1;
            }
            .dropdown {
                position: absolute;
                z-index: 1000;
                flex: 3 1;
                margin-top: -10px;
                // margin-left: 15px;
                // margin-right: 25px;
            }
        }
    }
}

.fine {
    .SearchBar {
        border: #798394 1px solid;
        box-shadow: none;
        border-radius: $boarderRadius;
    }

    .dropdown {
        border: #798394 1px solid;
        border-top: none;
    }
}

.shadow {
    .dropdown {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
    }
}
.container {
    position: relative;
    width: 100%;
    // width: 100vw;

    .fullWidth {
        display: flex;
        width: 100%;

        .dropdown {
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            width: 100%;
            background-color: white;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
            border-radius: 0px 0px $boarderRadius $boarderRadius;

            .item.selected {
                div:first-child {
                    background-color: #e4e8ee;
                    padding-bottom: 5px;
                    margin-bottom: 5px;
                    margin-top: 5px;
                    padding-top: 5px;
                }
            }

            .item {
                &.title {
                    font-family: "Roboto";
                    font-style: normal;
                    font-weight: 600;
                    font-size: 20px;
                    margin-left: -5px;
                }

                &:first-of-type {
                    padding-top: 10px;
                }

                align-self: center;

                div {
                    &:first-child {
                        border-radius: $boarderRadius;
                        padding: 10px;
                        align-self: center;
                    }
                }

                cursor: pointer;
                height: 40px;
                padding-left: 5px;
                display: flex;

                .imgHolder {
                    margin-left: auto;
                    margin-right: 20px;
                    width: 39px;
                    height: 39px;

                    img {
                        align-self: center;
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
            }
        }
    }
}
