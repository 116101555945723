@import "../../scss/_variables.scss";

/* Tooltip container */
.tooltip {
    position: relative;
    display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: $gray-4;
    text-align: center;
    font-size: 12px;
    padding: 10px;
    border-radius: 6px;
    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
    top: -5px;
    right: 150%;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
    visibility: visible;
}

.tooltip .tooltiptext::after {
    content: " ";
    position: absolute;
    top: 20%;
    left: 100%; /* To the left of the tooltip */
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent $gray-4;
}

@include media-xl {
    .tooltip .tooltiptext {
        left: 150%;
        right: auto;
    }

    .tooltip .tooltiptext::after {
        right: 100%;
        left: auto;
        border-color: transparent $gray-4 transparent transparent;
    }
}
