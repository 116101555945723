// All
.m-0 {
    margin: 0;
}

.m-5 {
    margin: 5px;
}

.m-10 {
    margin: 10px;
}

.m-15 {
    margin: 15px;
}

.m-20 {
    margin: 20px;
}

.m-25 {
    margin: 25px;
}

.m-30 {
    margin: 30px;
}

// left
.ml-0 {
    margin-left: 0;
}

.ml-5 {
    margin-left: 5px;
}

.ml-10 {
    margin-left: 10px;
}

.ml-15 {
    margin-left: 15px;
}

.ml-20 {
    margin-left: 20px;
}

.ml-25 {
    margin-left: 25px;
}

.ml-30 {
    margin-left: 30px;
}

// bottom
.mb-0 {
    margin-bottom: 0;
}

.mb-5 {
    margin-bottom: 5px;
}

.mb-10 {
    margin-bottom: 10px;
}

.mb-15 {
    margin-bottom: 15px;
}

.mb-16 {
    margin-bottom: 16px;
}

.mb-20 {
    margin-bottom: 20px;
}

.mb-25 {
    margin-bottom: 25px;
}

.mb-30 {
    margin-bottom: 30px;
}

// right
.mr-0 {
    margin-right: 0;
}

.mr-5 {
    margin-right: 5px;
}

.mr-10 {
    margin-right: 10px;
}

.mr-15 {
    margin-right: 15px;
}

.mr-20 {
    margin-right: 20px;
}

.mr-25 {
    margin-right: 25px;
}

.mr-30 {
    margin-right: 30px;
}

// top
.mt-0 {
    margin-top: 0;
}

.mt-5 {
    margin-top: 5px;
}

.mt-10 {
    margin-top: 10px;
}

.mt-15 {
    margin-top: 15px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-25 {
    margin-top: 25px;
}

.mt-30 {
    margin-top: 30px;
}
